
const cardData = [
  {
    id:"0",
    img: require("./components/images/tid.png"),
    title: "Track It Down - A digital way to find your belongings",
    des: "“Track It Down” is a web application specifically designed to address the problem of lost items on the KMIT campus. Many individuals struggle to locate their lost belongings as they cannot remember where they misplaced them. Existing methods, such as relying on WhatsApp groups to share information about lost or found items, lack reliability in ensuring that the message reaches the correct person. Track It Down aims to provide a centralized platform for finding lost items and reporting found items.",
    readmore:"https://github.com/Shaik-Sohail-72/Track-It-Down#readme",
    chipstitle1:"Reactjs",
    chipstitle2:"Nodejs",
    chipstitle3:"Expressjs",
    chipstitle4:"MongoDB",  
    chipstitle5:"AWS S3",
    visit_link: "https://tid-72.netlify.app/",
    git_link: "https://github.com/Shaik-Sohail-72/Track-It-Down"
  },
  {
    id:"1",
    img: require("./components/images/nids.png"),
    title: "Network Intrusion Detection System (NIDS), with Machine Learning and Deep Learning, Recurrent Neural Network models, MERN web I/O System.",
    des: "Large numbers of businesses were affected by data infringes and Cyber -attacks due to dependency on internet. To prevent such malicious activity, the network requires a system that detects anomaly and inform the user and alerts the user.",
    readmore:"https://github.com/Shaik-Sohail-72/Network-Intrusion-Detection-Using-Deep-Learning#readme",
    chipstitle1:"Machine Learning - Deep Learning",
    chipstitle2:"KNN - Random Forest - CNN - LSTM",
    chipstitle3:"Python - MERN Stack",
    chipstitle4:"MongoDB",  
    chipstitle5:"NSL - KDD",
    visit_link: "https://drive.google.com/file/d/1o76ZBdZ0vIAFx2EHPrKCtG12oNUeEsdu/view?usp=drivesdk",
    git_link: "https://github.com/Shaik-Sohail-72/Network-Intrusion-Detection-Using-Deep-Learning"
  },
  {
    id:"2",
    img: require("./components/images/flowerback1.png"),
    title: "Iris Flower type Prediction and Classification with Machine Learning and MERN web I/O System.",
    des: "This project predicts the type of iris flower by using machine learning and iris dataset. K-Nearest-Neighbor algorithm used for multi class classification. The user enter the parameters (sepal length, sepal width, petal length, petal width) in the front end",
    readmore:"https://github.com/Shaik-Sohail-72/Iris-Flower-Type-Prediction-and-Classification-with-ML-and-MERN#readme",
    chipstitle1:"Machine Learning",
    chipstitle2:"Python",
    chipstitle3:"KNN - Scikit-Learn - Iris",
    chipstitle4:"MERN Stack",
    chipstitle5:"Heroku",
    visit_link: "https://iris-flower-72.herokuapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/Iris-Flower-Type-Prediction-and-Classification-with-ML-and-MERN"
  },
  {
    id:"3",
    img: require("./components/images/keeper.png"),
    title: "Keeper App",
    des: "Keeper app which is a clone of google keep. using reactjs and material UI. So whenever user have any new ideas then the user can simply take a note and add it to keeper app.",
    readmore:"https://github.com/Shaik-Sohail-72/Keeper#readme",
    chipstitle1:"HTML",
    chipstitle2:"CSS",
    chipstitle3:"Reactjs",
    chipstitle4:"Material UI",
    chipstitle5:"Netlify",
    visit_link: "https://keeper-app72.netlify.app/",
    git_link: "https://github.com/Shaik-Sohail-72/Keeper"
  },
  {id:"4",
    img: require("./components/images/secrets.png"),
    title: "Secrets",
    des: "Don't keep your secrets, share them anonymously over the world! In this  webapp, providing very strong security to the users by authenticating users with Google.",
    readmore:"https://github.com/Shaik-Sohail-72/Secrets#readme",
    chipstitle1:"HTML - CSS - Bootstrap",
    chipstitle2:"EJS - Nodejs - Express",
    chipstitle3:"Passport google OAuth 2.0",
    chipstitle4:"MongoDB",
    chipstitle5:"AWS",
    visit_link: "https://secrets72.herokuapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/Secrets"
  },
  {id:"5",
    img:require("./components/images/emojipedia.png"),
    title: "Emojipedia",
    des: "A dictionary for emojis. Which contains most used emojis, As well as name of the emojis and meaning of the emjojis.",
    readmore:"https://github.com/Shaik-Sohail-72/Emojipedia#readme",
    chipstitle1:"HTML",
    chipstitle2:"CSS",
    chipstitle3:"Reactjs",
    chipstitle4:"AWS",
    chipstitle5:"Heroku",
    visit_link: "https://emojipedia72.netlify.app/",
    git_link: "https://github.com/Shaik-Sohail-72/Emojipedia"
  },
  {id:"6",
    img: require("./components/images/blog.png"),
    title: "Blog",
    des: "users can write the blog post and  compose or publish it to the world. Users can share their story, knowledge , ideas or any thing etc. to the world. Daily journaling is the act of thinking about your life and writing it down each day.",
    readmore:"https://github.com/Shaik-Sohail-72/Blog#readme",
    chipstitle1:"HTML - CSS - Bootstrap",
    chipstitle2:"EJS",
    chipstitle3:"Nodejs - Express",
    chipstitle4:"MongoDB",
    chipstitle5:"AWS",
    visit_link: "https://blog72.herokuapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/Blog"
  },
  {id:"7",
    img: require("./components/images/newsletter.png"),
    title: "News Letter",
    des: "It is a sign up app. And it is integration with MailChimp. Signed users gets sent to our list on MailChimp, and all of that data gets added into our list as new subscriber gets added.",
    readmore:"https://github.com/Shaik-Sohail-72/News-Letter#readme",
    chipstitle1:"HTML - CSS - Bootstrap",
    chipstitle2:"Nodejs - Express",
    chipstitle3:"API",
    chipstitle4:"MongoDB",
    chipstitle5:"AWS",
    visit_link: "https://newsletter72.herokuapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/News-Letter"
  },
  {id:"8",
    img: require("./components/images/todolist.png"),
    title: "Todo List",
    des: "The user can add tems and delete the item , all the list are store in the databse. User can create their own custom list with their name ... ",
    readmore:"https://github.com/Shaik-Sohail-72/Todo-List#readme",
    chipstitle1:"HTML - CSS",
    chipstitle2:"EJS",
    chipstitle3:"Nodejs - Express",
    chipstitle4:"MongoDB",
    chipstitle5:"AWS",
    visit_link: "https://todolist-webapp72.herokuapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/Todo-List"
  },
  {id:"9",
    img: require("./components/images/todoapp.png"),
    title: "Todo React App",
    des: "A beautiful Todo app without database using reactjs. Whenever user click on the item, the item gets deleted.",
    readmore:"https://github.com/Shaik-Sohail-72/Todo-React-App#readme",
    chipstitle1:"HTML",
    chipstitle2:"CSS",
    chipstitle3:"Reactjs",
    chipstitle4:"Netlify",
    chipstitle5:"Heroku",
    visit_link: "https://todo-app72.netlify.app/",
    git_link: "https://github.com/shaik-sohail-72"
  },
  {id:"10",
    img: require("./components/images/clock.png"),
    title: "Clock",
    des: "A real time clock app using reactjs .",
    readmore:"https://github.com/Shaik-Sohail-72/Clock-React-App#readme",
    chipstitle1:"HTML",
    chipstitle2:"CSS",
    chipstitle3:"Reactjs",
    chipstitle4:"Netlify",
    chipstitle5:"Heroku",
    visit_link: "https://clock72.netlify.app/",
    git_link: "https://github.com/Shaik-Sohail-72/Clock-React-App"
  },
  {id:"11",
    img: require("./components/images/weather.png"),
    title: "Weather",
    des: "live weather report using weather API. It displays the weather report of a particular location to the users that they're interested in.",
    readmore:"https://github.com/Shaik-Sohail-72/Weather#readme",
    chipstitle1:"HTML",
    chipstitle2:"JS",
    chipstitle3:"API",
    chipstitle4:"AWS",
    chipstitle5:"Heroku",
    visit_link: "https://weather-webapp72.herokuapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/Weather"
  },
  {id:"12",
    img: require("./components/images/drum.png"),
    title: "Drum Kit",
    des: "Using DOM, event listeners and keystrokes. Whenever the user tap on keyboard or clicking on a particular button . then it play respective drum sound.",
    readmore:"https://github.com/Shaik-Sohail-72/Drum-Kit#readme",
    chipstitle1:"HTML",
    chipstitle2:"CSS",
    chipstitle3:"JS",
    chipstitle4:"Document Object Model",
    chipstitle5:"Firebase",
    visit_link: "https://drum-kit72.firebaseapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/Drum-Kit"
  },
  {id:"13",
    img: require("./components/images/dice.png"),
    title: "Dice Game",
    des: "Using DOM. it has Player 1 dice and Player 2 dice. And when the user refresh the website then two dice get thrown. And website will announce a winner depending on whose dice value was higher.",
    readmore:"https://github.com/Shaik-Sohail-72/Dice-Game#readme",
    chipstitle1:"HTML",
    chipstitle2:"CSS",
    chipstitle3:"JS",
    chipstitle4:"Document Object Model",
    chipstitle5:"Firebase",
    visit_link: "https://dice-game72.firebaseapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/Dice-Game"
  },
  {id:"14",
    img: require("./components/images/tindog.png"),
    title: "TinDog",
    des: "A startup landing page using Bootstrap and advanced CSS. It has a title section, as well as features sections, a carousel to browse testimonials, a pricing plan section, and a call to action where you get your users to download your app, and finally a nice little footer with socials icons.",
    readmore:"https://github.com/Shaik-Sohail-72/TinDog#readme",
    chipstitle1:"HTML",
    chipstitle2:"CSS",
    chipstitle3:"Bootstrap",
    chipstitle4:"Icon Library",
    chipstitle5:"Firebase",
    visit_link: "https://tindog-website72.firebaseapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/TinDog"
  },
  {id:"15",
    img: require("./components/images/css.png"),
    title: "CSS Portfolio",
    des: "Portfolio using  various CSS properties. like Font, Text, Color and background, Box and other properties etc.",
    readmore:"https://github.com/Shaik-Sohail-72/CSS-Portfolio#readme",
    chipstitle1:"HTML",
    chipstitle2:"C",
    chipstitle3:"S",
    chipstitle4:"S",
    chipstitle5:"Firebase",
    visit_link: "https://css-portfolio72.firebaseapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/CSS-Portfolio"
  },
  {id:"16",
    img: require("./components/images/html.png"),
    title: "HTML Personal Site",
    des: "Personal site using HTML various concepts like Basic tags, Formatting, Body attributes, Lists, Text tags, Graphical elements, Links, Forms , HTML5 input tag attributes, Tables , Table attributes etc…",
    readmore:"https://github.com/Shaik-Sohail-72/HTML-Personal-Site#readme",
    chipstitle1:"H",
    chipstitle2:"T",
    chipstitle3:"M",
    chipstitle4:"L",
    chipstitle5:"Firebase",
    visit_link: "https://html-personal-site72.firebaseapp.com/",
    git_link: "https://github.com/Shaik-Sohail-72/HTML-Personal-Site"
  },
];
export default cardData;
