import React from "react";
import Topsec from "./Topsec";
import Middlesec from "./Middlesec";
import Footer from "./Footer";

function App() {
  return (<div>
    <Topsec></Topsec>
    <Middlesec></Middlesec>
    <Footer />
    </div>
  );
}
export default App;
