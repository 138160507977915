import React from "react";
import { Box, Grid, makeStyles, Typography, Tooltip } from "@material-ui/core";
import { GitHub, Linkedin, Instagram, Facebook, Mail } from "react-feather";
import { Fade, Bounce, Zoom} from "react-reveal"
const useStyles = makeStyles(() => ({
  container: {
    padding: "30px",
    height: "100%",
    backgroundColor:"#222"
  },
  circleimg: {
    borderRadius : "50%",
    border: "5px solid #fff",
    width: "135px",
    height: "135px"
  },
  heading: {
    fontWeight: "bold",
    fontSize: "40px",
    color:"#fff"
  },
  subHeading: {
    fontSize: "20px",
    width: "100%",
    color:"#fff"
  },
  subHeading2: {
    fontSize: "15px",
    width: "100%",
    color:"#ccc"
  },
  h3: {
    fontSize: "30px",
    width: "100%",
    paddingTop:"10px",
    paddingBottom:"10px",
    color:"#fff",
    textAlign:"center"
  },
  socialIcon: {
    display: "inline-block",
    width: "30px",
    height: "30px",
    marginRight: "20px",
  },
  socialIconLink: {
    color: "#fff",
  },
  link: {
    color: "#fff"
  },
  "@media only screen and (max-width:600px)": {
    container: {
      padding: "20px",
    },
    subHeading: {
      fontSize: "20px",
      width: "100%"
    },
  },
}));
function Topsec()
{
  const styles = useStyles();
  return (<div>
    <Grid
      container
      alignItems="center"
      justify="flex-start"
      className={styles.container}
    >
      <Grid item><Fade top>
      <a href={require('./images/profile_img.jpg')} target="_blank" rel="noopener noreferrer">
      <img
        src={require('./images/profile_img.jpg')}
        alt="Shaik Sohail Hussain"
        className={styles.circleimg}
      />
    </a></Fade>
        <Box mt={2}><Fade right>
          <Typography
            variant="h1"
            className={styles.heading}
            color="textPrimary">
            Hey, I'm Sohail
          </Typography></Fade>
        </Box>
        <Box mt={3}>
  <Fade right>
    <Typography className={styles.subHeading}>
      👨‍💻 Software Engineer | Creative Problem Solver | Technology Enthusiast | Fitness Model
    </Typography>
  </Fade>
</Box>
        <Box mt={3}>
        <Fade left>
          <Typography
            className={styles.subHeading2}>
            I’m a Computer Science graduate and software engineer, specializing in full-stack development, Python, and AWS.
          </Typography></Fade>
        </Box>
        <Box mt={3}>
  <Fade left>
    <Typography className={styles.subHeading2}>
    I have developed a platform{' '}
      <a 
        href="https://thefithub.in" 
        target="_blank" 
        rel="noopener noreferrer" 
        style={{ color: '#64b5f6', textDecoration: 'none' }}
      >
        thefithub.in
      </a>, 
      that empowers gym owners with advanced management tools and financial insights, fostering professionalism and driving optimal growth within the fitness industry. This project has honed my skills in building scalable applications and integrating complex functionalities.
    </Typography>
  </Fade>
</Box>
        <Box mt={2}>
        <Fade left>
          <Typography
            className={styles.subHeading2}>
            My journey as a developer has led me to build a wide range of web applications, from frontend to backend, integrating APIs, and databases, and even incorporating machine learning algorithms.
          </Typography></Fade>
        </Box>
        <Box mt={2}>
        <Fade left>
          <Typography
            className={styles.subHeading2}>
            I have a strong command of Python, Java, C++, C, and JavaScript, enabling me to craft robust and efficient applications. My proficiency in Object-Oriented Programming (OOP) ensures clean and maintainable code, while my deep knowledge of data structures and algorithms allows me to devise optimized solutions. Additionally, I am well-versed in using Linux OS, Git version control, and databases like MySQL and MongoDB.
          </Typography></Fade>
        </Box>
        <Box mt={2}>
        <Fade left>
          <Typography
            className={styles.subHeading2}>
            Web development is my true passion. My toolkit includes HTML, CSS, and Bootstrap for sleek and responsive designs, while Node.js and Express.js power my server-side logic. I'm also experienced with React.js and EJS for dynamic frontends and have worked with Material-UI to create aesthetically pleasing interfaces.
          </Typography></Fade>
        </Box>
        <Box mt={2}>
        <Fade left>
          <Typography
            className={styles.subHeading2}>
            
            Through my work on various projects and collaborations, I’ve enhanced my leadership and teamwork skills. I quickly adapt to new challenges and technologies.
          </Typography></Fade>
        </Box>
        <Zoom right>
        <Box mt={3}> 
          <Tooltip title="github.com/shaik-sohail-72" arrow placement="top">
            <Box className={styles.socialIcon}>
              <a
                href="https://github.com/shaik-sohail-72"
                alt="github"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialIconLink}>
                <GitHub strokeWidth={1.5} size={30} />
              </a>
            </Box>
          </Tooltip>
          <Tooltip title="linkedin.com/shaik-sohail-72" arrow placement="top">
            <Box className={styles.socialIcon}>
              <a href="https://www.linkedin.com/in/shaik-sohail-a7b7aa240"
                alt="linkedin"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialIconLink}>
                <Linkedin strokeWidth={1.5} size={30} />
              </a>
            </Box>
          </Tooltip>
         
          
          <Tooltip title="shaiksohailhu7n@gmail.com" arrow placement="top">
            <Box className={styles.socialIcon}>
              <a
                href="mailto:shaiksohailhu7n@gmail.com"
                alt="email"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialIconLink}>
                <Mail strokeWidth={1.5} size={30} />
              </a>
            </Box>
          </Tooltip>
        </Box>
        </Zoom>
        <Box mt={2}>
        <Bounce bottom>
          <Typography variant="h1"
            className={styles.h3}>
            Check Out
          </Typography></Bounce>
        </Box>
      </Grid>
    </Grid>
    </div>
  )
}
export default Topsec;
